import React, { Component } from 'react';

class Portfolio extends Component {
  render() {
    if (this.props.data) {
      var projectsRow1 = this.props.data.projects
        .slice(0, 4)
        .map(function (projects) {
          var projectImage = 'images/portfolio/' + projects.image;
          return (
            <div
              key={projects.title}
              className="columns portfolio-item bgrid-quarters"
            >
              <div className="item-wrap">
                <a href={projects.url} title={projects.title}>
                  <img alt={projects.title} src={projectImage} />
                  <div className="overlay">
                    <div className="portfolio-item-meta">
                      <h5>{projects.title}</h5>
                      <p>{projects.category}</p>
                    </div>
                  </div>
                  <div className="link-icon">
                    <i className="fa fa-link"></i>
                  </div>
                </a>
              </div>
            </div>
          );
        });
      var projectsRow2 = this.props.data.projects
        .slice(4, 8)
        .map(function (projects) {
          var projectImage = 'images/portfolio/' + projects.image;
          return (
            <div
              key={projects.title}
              className="columns portfolio-item bgrid-quarters"
            >
              <div className="item-wrap">
                <a href={projects.url} title={projects.title}>
                  <img alt={projects.title} src={projectImage} />
                  <div className="overlay">
                    <div className="portfolio-item-meta">
                      <h5>{projects.title}</h5>
                      <p>{projects.category}</p>
                    </div>
                  </div>
                  <div className="link-icon">
                    <i className="fa fa-link"></i>
                  </div>
                </a>
              </div>
            </div>
          );
        });
    }

    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Projects I have made</h1>
            <div className="row">
              <div id="portfolio-wrapper" className="bgrid-quarters">
                {projectsRow1}
              </div>
            </div>
            <div className="row">
              <div
                id="portfolio-wrapper"
                className="bgrid-quarters align-center"
              >
                {projectsRow2}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
